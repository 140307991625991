<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }}) </small>
        {{ id ? object.name : "" }} (Продажа объекта)
      </template>

      <v-row>
        <v-col cols="12" class="pb-0">
          <a-form-model
            v-model="data"
            :model="model"
            :errors="errors"
            :config="{ dense: true }"
            @validate="validate($event)"
          />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :title="'Продать'"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl } from "../../components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl],

  props: {
    value: Boolean,
    id: Number,
    object: Object,
  },
  data() {
    return {
      api: "/mechti/objects",
      model: this.$store.getters["config/get"].models.objects.formSale,
    };
  },
  methods: {
    async post(data, api) {
      const resp = await this.$axios.get(this.api, {
        params: {
          filters: { status_fin: [0, 1], id: this.id },
        },
      }); 
      if (!(resp.data.status == "ok")) {
        this.$root.$emit("show-info", {
          text: "Ошибка....",
        });
        return;
      }
      console.log(resp.data.data.length);
      if (resp.data.data.length != 1) {
        this.$root.$emit("show-info", {
          text: "Не возможно продать объект...",
        });
        return;
      }
      console.log("sale", api, data);
      if (!this.id) return;
      data.id = this.id;
      data.status_fin = 2;
      this.loading = true;
      let response = await this.$axios.post(api, data, {
        params: { filters: { status_fin: [0, 1] } },
      });
      this.loading = false;
      let status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Объект продан",
        });
      }
      this.afterSave(this.data, status);
    },
    afterSave(data, status) {
      if (status) {
        this.$emit("input");
        this.$router.push({ name: "objects" });
      }
    },
  },
};
</script>